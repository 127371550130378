import styled from 'styled-components'
import adonis from '../assets/VILLA-ADONIS-2-2.jpeg'
import laura from '../assets/2-Villa-Laura.jpeg'
import white from '../assets/VILLA-WHITE-11.jpeg'
import hermes from '../assets/villa-hermes.jpeg'
import artemis from '../assets/villa-artemis.jpeg'
import alexandros from '../assets/villa-alexandros.webp'
import natalia from '../assets/villa-natalia.webp'
import ilia from '../assets/villa-ilia.webp'
import agios from '../assets/villa-agios-fokas.webp'
import marchello from '../assets/machello.jpeg'
import kelly from '../assets/villa-kelly.jpeg'
import palmTree from '../assets/palm-tree.jpeg'
import agnanti from '../assets/The_Hotel_7862.jpeg'
import omilos from '../assets/omilos.jpeg'
import paradiso from '../assets/paradiso.jpeg'
import React from 'react'

function Accomodation() {
    const [selectedTab, setSelectedTab] = React.useState('villas');
  return (
    <AccomodationContainer id={'accomodation'}>
        <div>
            <SectionTitle>Hebergement</SectionTitle>
            <Separator/>
        </div>
        <PSContainer>PS: Les hébergements proposés sont à titre indicatif et non obligatoires</PSContainer>
        <TabsContainer>
            <Tabs onClick={() => {setSelectedTab('villas')}} className={selectedTab === 'villas' ? 'selected' : ''}>Villas</Tabs>
            <Tabs onClick={() => {setSelectedTab('hotels')}} className={selectedTab === 'hotels' ? 'selected' : ''}>Hôtels</Tabs>
        </TabsContainer>
        {selectedTab === 'villas' && <><CardsContainer>
            <AccomodationCard>
                <CardTop id='adonis'>
                    {/* <img src={adonis} alt={'villa-adonis'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA ADONIS</AcommodationName>
                    <AccomodationNbPeople>6-7 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>110m2<br/>3 chambres<br/>2 salles de bain<br/>Plage à 50m</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>220€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://bluegreens.gr/villa-adonis/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='laura'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA LAURA</AcommodationName>
                    <AccomodationNbPeople>6-7 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>80m2<br/>3 chambres<br/>2 salles de bain<br/>Accès plage</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>220€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://bluegreens.gr/laura/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='white'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA WHITE</AcommodationName>
                    <AccomodationNbPeople>6-8 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>250m2<br/>3 chambres<br/>2 salles de bain<br/>Piscine et Jacuzzi</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>500€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://bluegreens.gr/villa-white/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='hermes'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA HERMES</AcommodationName>
                    <AccomodationNbPeople>10-12 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>175m2<br/>5 chambres<br/>4 salles de bain<br/>Piscine partagée</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>517€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.mythicalvillas.com/villas/villa-hermes-paros/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='artemis'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA ARTEMIS</AcommodationName>
                    <AccomodationNbPeople>11-12 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>175m2<br/>5 chambres<br/>4 salles de bain<br/>Piscine partagée</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>517€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.mythicalvillas.com/villas/villa-artemis-paros/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='alexandros'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA ALEXANDROS</AcommodationName>
                    <AccomodationNbPeople>10 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>5 chambres<br/>4 salles de bain<br/>Piscine partagée</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>500€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.airbnb.fr/rooms/18704544'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='natalia'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA NATALIA</AcommodationName>
                    <AccomodationNbPeople>6-7 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>3 chambres<br/>3 salles de bain<br/>Piscine, proche de la plage</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>230€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.airbnb.fr/rooms/12697816'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='ilia'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA ILIA</AcommodationName>
                    <AccomodationNbPeople>8-9 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>4 chambres<br/>3 salles de bain<br/>Piscine</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>480€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.airbnb.fr/rooms/10450827'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='agios-fokas'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA AGIOS FOKAS</AcommodationName>
                    <AccomodationNbPeople>4 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>2 chambres<br/>1 salle de bain<br/>Proche de la plage</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>200€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.airbnb.fr/rooms/14099956'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='marchello'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>MARCHELLO BEACH HOUSE</AcommodationName>
                    <AccomodationNbPeople>4 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>2 chambres<br/>1 salle de bain<br/>200m de la plage</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>130€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://marcello-country-houses.business.site/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='kelly'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>VILLA KELLY</AcommodationName>
                    <AccomodationNbPeople>4-5 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>77m2<br/>3 chambres<br/>1 salle de bain<br/>Piscine partagée</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>220€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.airbnb.fr/rooms/43004885'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='palm-tree'>
                    {/* <img src={laura} alt={'villa-laura'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>PALM TREE VILLA</AcommodationName>
                    <AccomodationNbPeople>4 pers</AccomodationNbPeople>
                    </div>
                    <AccomodationDesc>2 chambres<br/>1 salle de bain<br/>Piscine partagée</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>150€/jour</AccomodationPrice>
                        <ReservationLink><a href='https://www.paros-house.de/'>Voir</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
        </CardsContainer>
        <ExtraContainer>
            Pour toute demande d'information ou de réservation, contacter Mme.ALKISTIS ARCHIMANDRITOU
            <ContactLinksContainer>
                <a href={'tel:+306944655200'}>+306944655200</a>
                <a href={'mailto:villasbluegreens @gmail.com'}>villasbluegreens @gmail.com</a>
            </ContactLinksContainer>
            20% du prix sera à payer lors de la réservation, les 80% restants sur place<br/>(Possible d'enlever 24% équivalents à la TVA si le restant sur place est payé en liquide)
        </ExtraContainer>
        </>
        }
        {selectedTab === 'hotels' && <CardsContainer>
            <AccomodationCard>
                <CardTop id='agnanti'>
                    {/* <img src={hotel} alt={'hotel-parosagnanti'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>Paros Agnanti Resort</AcommodationName>
                    </div>
                    <AccomodationDesc>A 280m à pieds<br/>4 nuits obligatoires<br/>Option pour 10 chambres</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>270 €/nuit</AccomodationPrice>
                        <ReservationLink><a href='https://parosagnanti.reserve-online.net/?_ga=2.102488193.1477074139.1662361209-675044371.1647859224&checkin=2023-09-15&rooms=1&nights=5&adults=2'>Réserver</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='omilos'>
                    {/* <img src={hotel} alt={'hotel-parosagnanti'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>Omilos Beach Front House</AcommodationName>
                    </div>
                    <AccomodationDesc>A 500m à pieds<br/>2 nuits obligatoires<br/>Option pour 4 chambres</AccomodationDesc>
                    <DetailsBox>
                        <AccomodationPrice>170 €/nuit</AccomodationPrice>
                        <ReservationLink><a href='https://omilosbeachfronthouse.reserve-online.net/?checkin=2023-09-15&rooms=1&nights=2&adults=2&children=1&rate=390384'>Réserver</a></ReservationLink>
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
            <AccomodationCard>
                <CardTop id='paradiso'>
                    {/* <img src={hotel} alt={'hotel-parosagnanti'} /> */}
                </CardTop>
                <CardBottom>
                    <div>
                    <AcommodationName>Boutique Hotel Paros Paradiso</AcommodationName>
                    </div>
                    <AccomodationDesc>En cours de négociation</AccomodationDesc>
                    <DetailsBox>
                        {/* <AccomodationPrice>270 €/nuit</AccomodationPrice>
                        <ReservationLink><a href='https://parosagnanti.reserve-online.net/?_ga=2.102488193.1477074139.1662361209-675044371.1647859224&checkin=2023-09-15&rooms=1&nights=5&adults=2'>Réserver</a></ReservationLink> */}
                    </DetailsBox>
                </CardBottom>
            </AccomodationCard>
        </CardsContainer>}
    </AccomodationContainer>
  );
}

const AccomodationContainer = styled.div`
    min-height: calc(100vh - 70px);
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 750px) {
        padding-top: 35px;
    }
`;

const SectionTitle = styled.div`
    font-size: 2.5em;
    font-style: italic;
    font-family: basileus;
    width: fit-content;
    margin: 15px auto;
    color: #3F4C91;
`;

const CardsContainer = styled.div`
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    gap: 75px;
    overflow: scroll;
    padding: 30px 75px;

    @media (max-width: 750px) {
        padding: 20px;
        gap: 40px;
    }
`

const AccomodationCard = styled.div`
    min-width: 250px;
    max-width: 300px;
    background-color: white;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
`;

const CardTop = styled.div`
    height: 180px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    background-position: 70%;
    background-size: cover;

    &#adonis {
        background-image: url(${adonis});
    }

    &#laura {
        background-image: url(${laura});
    }

    &#white {
        background-image: url(${white});
    }

    &#hermes {
        background-image: url(${hermes});
    }

    &#artemis {
        background-image: url(${artemis});
    }

    &#alexandros {
        background-image: url(${alexandros});
    }

    &#natalia {
        background-image: url(${natalia});
    }

    &#ilia {
        background-image: url(${ilia});
    }

    &#agios-fokas {
        background-image: url(${agios});
    }

    &#marchello {
        background-image: url(${marchello});
    }

    &#kelly {
        background-image: url(${kelly});
    }

    &#palm-tree {
        background-image: url(${palmTree});
    }

    &#agnanti {
        background-image: url(${agnanti});
    }

    &#omilos {
        background-image: url(${omilos});
    }

    &#paradiso {
        background-image: url(${paradiso});
    }

    img {
        width: auto;
        height: 100%;
    }
`;

const CardBottom = styled.div`
    padding: 10px 25px;
    text-align: center;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const AcommodationName = styled.div`
    color: #3F4C91;
    font-size: 1.6em;
    text-align: center;
    margin-top: 5px;
    font-family: basileus;
`;

const AccomodationNbPeople = styled.div`
    color: #3F4C91;
    margin-top: 5px;
    font-family: basileus;
`;

const AccomodationDesc = styled.div`
    font-size: 0.85em;
`;

const ReservationLink = styled.div`
    border: 2px solid #3F4C91;
    cursor: pointer;
    padding: 5px 7px;

    a {
        text-decoration: none;
        color: #3F4C91;
    }
`;

const Separator = styled.div`
    width: 100px;
    heigth: 1px;
    border-bottom: 1px solid #3F4C91;
    margin: 0 auto;
`;

const DetailsBox = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AccomodationPrice = styled.div`
    margin: auto 0;
`;

const TabsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    
    @media (max-width: 750px) {
        padding: 0 20px;
    }
`;

const Tabs = styled.div`
    padding: 7px 10px;
    cursor: pointer;
    color: #3F4C91;

    &.selected {
        border-bottom: 1px solid #3F4C91;
    }
`;

const PSContainer = styled.div`
    font-size: 0.8em;
    color: #3F4C91;
    text-align: center;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 15px;

    @media (max-width: 750px) {
        padding: 0 20px;
    }
`;

const ExtraContainer = styled.div`
    text-align: center;
    margin: 25px auto;
    font-size: 0.9em;
    color: #3F4C91;
    border: 2px #3F4C91 dashed;
    padding: 10px 30px;

    @media (max-width: 750px) {
        margin: 20px;
    }
`;

const ContactLinksContainer = styled.div`
    gap: 25px;
    display: flex;
    justify-content: center;
    margin: 7px 0;

    a {
        color: #3F4C91;
        font-weight: bold;
    }

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 5px;
    }
`;

export default Accomodation