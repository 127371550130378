import styled from 'styled-components'
import logo from '../assets/logo-blue.png'

function Logo() {
  return (
    <LogoStyled src={logo} alt={'logo'}/>
  );
}

const LogoStyled = styled.img`
  height: 100%;
  width: 100%;
`;

export default Logo