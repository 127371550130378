import styled from 'styled-components'
import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function setInitialFormValues() {
    return {
        firstname: '',
        lastname: '',
        email: '',
        rsvp: undefined,
        guest: undefined,
        guestFirstname: '',
        guestLastname: '',
        kids: undefined,
        nbkids: undefined,
        kidsAges: Array(0),
        allergies: '',
        specialFood: '',
        arrivalDate: undefined,
        leaveDate: undefined,
        forBrides: ''
    }
}

function Rsvp() {
    const [formValues, setFormValues] = React.useState(setInitialFormValues());
    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async (e) =>{
        e.preventDefault()
        setLoading(true)

        axios.post('https://sheet.best/api/sheets/1b56752c-6052-4fb3-b619-5f4e2c4a3e47', formValues)
            .then(response => {
            if (response.status === 200) {
                toast.success('Réponse envoyée !', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    });
                setFormValues(setInitialFormValues())
                setLoading(false)
            } else {
                toast.success('Une erreur est survenue lors de l\'envoi, veuillez réessayer', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    });
                setLoading(false)
            }
        })
    }
    
  return (
    <AccomodationContainer id={'rsvp'}>
        <SectionTitle>RSVP</SectionTitle>
        <Separator/>
        <CardsContainer>
        <form onSubmit={handleSubmit}>
            <NameContainer>
                <InputContainer>
                    <StyledLabel>Nom :</StyledLabel>
                    <InputStyled
                        type="text"
                        name={'lastname'}
                        value={formValues.lastname}
                        onChange={(e) => setFormValues(values => ({...values, lastname: e.target.value}))}
                        />
                </InputContainer>
                <InputContainer>
                    <StyledLabel>Prénom :</StyledLabel>
                    <InputStyled
                        type="text" 
                        name={'firstname'}
                        value={formValues.firstname}
                        onChange={(e) => setFormValues(values => ({...values, firstname: e.target.value}))}
                        />
                </InputContainer>
            </NameContainer>
            <InputContainer>
                    <StyledLabel>Email :</StyledLabel>
                    <InputStyled
                        type="mail"
                        name={'email'}
                        value={formValues.email}
                        onChange={(e) => setFormValues(values => ({...values, email: e.target.value}))}
                        />
                </InputContainer>
            <RsvpContainer>
                <RadioLabel>RSVP :</RadioLabel>
                <RadioContainer>
                    <div>
                        <StyledRadio
                            type="radio"
                            name={'rsvp'}
                            value="yes"
                            checked={formValues.rsvp === "yes"}
                            onChange={(e) => setFormValues(values => ({...values, rsvp: e.target.value}))}
                            />
                        <label>Oui</label>
                    </div>
                    <div>
                        <StyledRadio
                            type="radio"
                            name={'rsvp'}
                            value="no"
                            checked={formValues.rsvp === "no"}
                            onChange={(e) => setFormValues(values => ({...values, rsvp: e.target.value}))}
                            />
                        <label>Non</label>
                    </div>
                </RadioContainer>
            </RsvpContainer>
            <RsvpContainer>
                <RadioLabel>Accompagnateur :</RadioLabel>
                <RadioContainer>
                    <div>
                        <StyledRadio
                            type="radio"
                            name={'guest'}
                            value="yes"
                            checked={formValues.guest === "yes"}
                            onChange={(e) => setFormValues(values => ({...values, guest: e.target.value}))}
                            />
                        <label>Oui</label>
                    </div>
                    <div>
                        <StyledRadio
                            type="radio"
                            name={'guest'}
                            value="no"
                            checked={formValues.guest === "no"}
                            onChange={(e) => setFormValues(values => ({...values, guest: e.target.value}))}
                            />
                        <label>Non</label>
                    </div>
                </RadioContainer>
            </RsvpContainer>
            {formValues.guest === 'yes' && <NameContainer>
                <InputContainer>
                    <StyledLabel>Nom :</StyledLabel>
                    <InputStyled
                        type="text"
                        name={'guestFirstname'}
                        value={formValues.guestFirstname}
                        onChange={(e) => setFormValues(values => ({...values, guestFirstname: e.target.value}))}
                        />
                </InputContainer>
                <InputContainer>
                    <StyledLabel>Prénom :</StyledLabel>
                    <InputStyled
                        type="text"
                        name={'guestLastname'}
                        value={formValues.guestLastname}
                        onChange={(e) => setFormValues(values => ({...values, guestLastname: e.target.value}))}
                        />
                </InputContainer>
            </NameContainer>}
            <div>
                <KidsContainer>
                    <RadioLabel>Enfants :</RadioLabel>
                    <RadioContainer>
                        <div>
                            <StyledRadio
                                type="radio"
                                name={'kids'}
                                value="yes"
                                checked={formValues.kids === "yes"}
                                onChange={(e) => setFormValues(values => ({...values, kids: e.target.value}))}
                                />
                            <label>Oui</label>
                        </div>
                        <div>
                            <StyledRadio
                                type="radio"
                                name={'kids'}
                                value="no"
                                checked={formValues.kids === "no"}
                                onChange={(e) => setFormValues(values => ({...values, kids: e.target.value}))}
                                />
                            <label>Non</label>
                        </div>
                    </RadioContainer>
                </KidsContainer>
                {formValues.kids === "yes" &&
                    <div>
                        <InputContainer>
                            <StyledLabel>Nombre d'enfants :</StyledLabel>
                            <InputStyled
                                type="number"
                                name={'nbkids'} 
                                min={1}
                                value={formValues.nbkids}
                                onChange={(e) => setFormValues(values => ({...values, nbkids: e.target.value, kidsAges: Array(Number(e.target.value)).fill(null)}))}
                                />
                        </InputContainer>
                        {formValues.kidsAges.length > 0 && <div>
                            <StyledLabel>Âge(s) :</StyledLabel>
                            <AgeContainer>
                                {formValues.kidsAges.map((elem, index) => {
                                    console.log(Array(5))
                                    console.log(formValues.kidsAges)
                                    console.log(index)
                                    return <AgeInput
                                    type="number"
                                    min={0}
                                    value={formValues.kidsAges[index]}
                                    onChange={(e) => {const newArray = formValues.kidsAges;
                                        newArray[index] = e.target.value;
                                        setFormValues(values => ({...values, kidsAges: newArray}))}}
                                    />
                                })}
                            </AgeContainer>
                        </div>}
                    </div>
                }
            </div>
            <InputContainer>
                <StyledLabel>Allergies :</StyledLabel>
                <InputStyled
                    type="text" 
                    name={'allergies'}
                    value={formValues.allergies}
                    onChange={(e) => setFormValues(values => ({...values, allergies: e.target.value}))}
                    />
            </InputContainer>
            <InputContainer>
                <StyledLabel>Régime spécial :</StyledLabel>
                <InputStyled
                    type="text" 
                    name={'specialFood'}
                    value={formValues.specialFood}
                    onChange={(e) => setFormValues(values => ({...values, specialFood: e.target.value}))}
                    />
            </InputContainer>
            <NameContainer>
                <InputContainer>
                    <StyledLabel>Date d'arrivée estimée :</StyledLabel>
                    <InputStyled
                        type="date" 
                        name={'arrivalDate'}
                        value={formValues.arrivalDate}
                        onChange={(e) => setFormValues(values => ({...values, arrivalDate: e.target.value}))}
                        />
                </InputContainer>
                <InputContainer>
                    <StyledLabel>Date de départ estimée :</StyledLabel>
                    <InputStyled
                        type="date" 
                        name={'leaveDate'}
                        value={formValues.leaveDate}
                        onChange={(e) => setFormValues(values => ({...values, leaveDate: e.target.value}))}
                        />
                </InputContainer>
            </NameContainer>
            <InputContainer>
                <StyledLabel>Un petit mot pour les mariés :</StyledLabel>
                <InputStyled
                    type="textarea" 
                    name={'forBrides'}
                    value={formValues.forBrides}
                    onChange={(e) => setFormValues(values => ({...values, forBrides: e.target.value}))}
                    />
            </InputContainer>
            <ButtonContainer >
                <StyledButton disabled={loading}>Submit</StyledButton>
            </ButtonContainer>
        </form>
        </CardsContainer>
    </AccomodationContainer>
  );
}

const AccomodationContainer = styled.div`
    min-height: calc(100vh - 70px);
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #2d345f;

    @media (max-width: 750px) {
        padding-top: 35px;
        width: 100%;
    }
`;

const SectionTitle = styled.div`
    font-size: 2.5em;
    font-style: italic;
    font-family: basileus;
    width: fit-content;
    margin: 15px auto;
    color: white;
`;

const CardsContainer = styled.div`
    width: fit-content;
    margin: 35px auto;
    padding: 35px;
    display: flex;
    justify-content: center;
    gap: 75px;
    align-items: center;
    background-color: white;

    @media (max-width: 750px) {
        padding: 20px;
        width: 90%;
        box-sizing: border-box;

        form {
            width: 100%;
        }
    }
`

const Separator = styled.div`
    width: 100px;
    heigth: 1px;
    border-bottom: 1px solid white;
    margin: 0 auto;
`;

const NameContainer = styled.div`
    display: flex;
    gap: 25px;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: unset;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const InputStyled = styled.input`
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 10%);
    border-radius: 3px;
    min-width: 250px;

    @media (max-width: 750px) {
        width: 100%;
        box-sizing: border-box;
        min-width: unset;
    }
`;

const StyledRadio = styled.input``;

const StyledLabel = styled.label`
    color: #3F4C91;
    font-size: 1.25em;
    font-family: basileus;
`

const RadioContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 25px;
`;

const RsvpContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const KidsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const RadioLabel = styled(StyledLabel)`
    text-align: center;
`;

const StyledButton = styled.button`
    border: 2px solid #3F4C91;
    background-color: white;
    color: #3F4C91;
    padding: 5px 7px;
    font-family: 'basileus';
    font-size: 1.2em;
    cursor: pointer;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

const AgeContainer = styled.div`
    display: flex;
    gap: 25px;
`;

const AgeInput = styled(InputStyled)`
    min-width: unset;
    max-width: 50px;
`;

export default Rsvp