import styled from 'styled-components'
import when from '../assets/when.png'
import where from '../assets/where.png'
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import Greece from '../assets/greece.svg';

function Details() {
  return (
    <DetailsContainer id={'details'}>
        <DetailsCard>
            <CardTop>
                <IconContainer>
                    <img src={where} alt={'where'} />
                </IconContainer>
                <CardTitle>Ou</CardTitle>
            </CardTop>
            <Separator />
            <CardBottom>
                <CardDesc>Paros</CardDesc>
                <CardLink>
                    <a href={'https://www.google.com/maps/place/Paros/@36.9301806,24.9656866,8.36z/data=!4m5!3m4!1s0x1498722c121b38ab:0x6cdef18eb96cf52a!8m2!3d37.0853941!4d25.1515337'} target={'_blank'} rel="noreferrer" >Voir sur la map</a>
                </CardLink>
            </CardBottom>
        </DetailsCard>
        <DetailsCard>
            <CardTop>
                <IconContainer>
                    <img src={when} alt={'when'} />
                </IconContainer>
                <CardTitle>Quand</CardTitle>
            </CardTop>
            <Separator />
            <CardBottom>
                <CardDesc>
                    <DescPrimary>16-17</DescPrimary>
                    <DescSecondary>Septembre 2023</DescSecondary>
                </CardDesc>
                <CardLink onClick={() => atcb_action({
                        "name":"Mariage d'Elyse et Damien",
                        "startDate":"2023-09-16",
                        "endDate":"2023-09-18",
                        "location":"Paros",
                        "options":[
                            "Apple",
                            "Google",
                            "Outlook.com",
                        ],
                        "timeZone":"Europe/Paris",
                        "trigger": "click",
                    }
)}>
                    Add to calendar
                </CardLink>
            </CardBottom>
        </DetailsCard>
    </DetailsContainer>
  );
}

const DetailsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    background-image: url(${Greece});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 750px) {
        flex-direction: column;
    }
`;

const DetailsCard = styled.div``;

const CardTop = styled.div``;

const CardBottom = styled.div`
    padding: 35px 25px;
    text-align: center;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 750px) {
        height: unset;
        padding: 20px 15px;
    }
`;

const IconContainer = styled.div`
    width: 50px;
    margin: auto;

    img {
        width: 100%;
    }
`;

const CardTitle = styled.div`
    color: #3F4C91;
    font-size: 2em;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 35px;
    font-family: basileus;

    @media (max-width: 750px) {
        margin: 20px 0;
    }
`;

const CardDesc = styled.div`
    font-size: 1.25em;

    @media (max-width: 750px) {
        margin-bottom: 20px;
    }
`;

const CardLink = styled.div`
    border: 2px solid #3F4C91;
    cursor: pointer;
    padding: 8px 20px;
    width: 115px;
    margin: 0 auto;
    background-color: white;

    a {
        text-decoration: none;
        color: black;
    }
`;

const Separator = styled.div`
    width: 100px;
    heigth: 1px;
    border-bottom: 1px solid #3F4C91;
    margin: auto;
`;

const DescPrimary = styled.div`
    font-weight: 500;
`

const DescSecondary = styled.div`
    font-weight: 300;
`

export default Details