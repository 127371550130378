import { Parallax } from 'react-parallax';
import styled from 'styled-components'
import Logo from './components/Logo'
import Menu from './components/Menu'
import Details from './components/Details'
import GettingThere from './components/GettingThere';
import Destination from './components/Destination';
import Planning from './components/Planning';
import Accomodation from './components/Accomodation';
import Rsvp from './components/Rsvp';
import { ToastContainer } from 'react-toastify';
import './App.css';
import PictureSection from './components/PictureSection';

function App() {
  return (
    <div class={'App'}>
      <Menu />
      <Parallax bgImage={require('./assets/background-image.png')} strength={150}>
        <ParallaxContent>
          <CenterLogoContainer>
            <Logo />
          </CenterLogoContainer>
          <DateContainer>16 & 17 Septembre 2023</DateContainer>
        </ParallaxContent>
      </Parallax>
      <Details />
      <Destination />
      <GettingThere/>
      <Planning />
      <Accomodation />
      <Rsvp />
      <PictureSection/>
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
            theme="light"
            />
    </div>
  );
}

const CenterLogoContainer = styled.div`
  height: 250px;
  width: 250px;
  display: flex;
  margin: auto;
  margin-bottom: 0;
`;

const ParallaxContent = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

const DateContainer = styled.div`
  color: white;
  font-size: 2em;
  width: fit-content;
  margin: auto;
  margin-top: 25px;
  font-family: DisneyHeroic;
  font-style: italic;
  font-weight: bold;

  @media (max-width: 750px) {
    text-align: center;
  }
`;

export default App;
