import styled from 'styled-components'
import paros from '../assets/paros.jpeg'

function Destination() {
  return (
    <DestinationContainer id={"destination"}>
      <BackgroundImage></BackgroundImage>
      <TextContainer>
        <Title>La destination</Title>
        <Separator />
        <DescTitle>Pourquoi Paros ?</DescTitle>
        <Desc>
          <p>Préparez vos valises, pour célébrer (enfin) notre mariage, on vous emmène sur l’île de Paros !<br/>
            Avec Damien on s’est toujours dit que notre mariage se ferait à l’étranger.<br/>
          Évidement, on a pensé au Sénégal mais 5 600 km en 3 jours c’est moins facile ;)<br/>
          Alors on a réfléchi et la Grèce s’est imposée à 100 % des voix.<br/>
          C’est un de nos plus beaux voyages et étant passionnée par la mythologie grecque il n’y avait plus d’hésitation !<br/>
          En septembre les touristes sont partis, l’île est presque à nous…<br/>
          Une eau turquoise, des maisons blanches, une touche de bleu sur les chapelles, une hospitalité et une gentillesse à toute épreuve vous attendent en Grèce. On vous a parlé du temps sublime qui prolongera vos vacances jusqu’en septembre ?<br/>
          Et pour que cette île soit encore plus belle grâce à vous, nous avons pensé à un thème couleur pour vos beaux vêtements : du bleu avec une touche de blanc.<br/>
          Tout se fera dans cette maison <a href={'https://bluegreens.gr/blue-green-villa/'}>#bluegreenvilla</a>, le mariage laïque dans le jardin, le dîner et la pool/beach party du lendemain.</p>
          <p>Nous vous avons sélectionnés des hôtels et maisons à quelques minutes à pieds de la villa, à vous de choisir si vous souhaitez y séjourner.</p>
          <p>On a hâte de célébrer cet événement avec vous et de fêter nos 20 ans avec nos familles et ami(e)s les plus proches.</p>
          <p>On vous aime<br/>
          Elyse&Damien</p>
        </Desc>
      </TextContainer>
    </DestinationContainer>
  );
}

const DestinationContainer = styled.div`
    display: flex;
    min-height: 100vh;

    @media (max-width: 750px) {
      flex-direction: column;
    }
`;

const BackgroundImage = styled.div`
    background-image: url(${paros});
    width: 62%;
    background-position: 70%;
    background-size: cover;

    @media (max-width: 750px) {
      width: 100%;
      height: 200px;
    }
`;

const TextContainer = styled.div`
    width: 38%;
    background-color: #2d345f;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    padding-top: 50px;

    @media (max-width: 750px) {
      width: 100%;
      padding: 20px;
      padding-top: 35px;
    }
`;

const Title = styled.div`
    font-size: 2.5em;
    font-style: italic;
    font-family: basileus;
`;

const Separator = styled.div`
    height: 1px;
    border-bottom: 1px solid white;
    width: 40%;
    margin-top: 10px;
    margin-bottom: 30px;
`;

const DescTitle = styled.div`
    font-size: 1.3em;
    margin-bottom: 5px;
`;

const Desc = styled.div`
    font-weight: 300;

    p {
      font-size: 0.9em;
      line-height: 1.3;

      a {
        color: white;
      }
    }
`;

export default Destination;
