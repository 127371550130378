import styled from "styled-components";
import bluegreen from "../assets/bluegreen-villa.jpeg";

function Planning() {
  return (
    <PlanningContainer id={"planning"}>
      
      <TextContainer>
        <Title>Le Programme</Title>
        <Separator />
        <Desc>
          <div>
            <DayTitle>16 Septembre</DayTitle>
            <DaySubtitleClickable onClick={() => {window.open('https://bluegreens.gr/blue-green-villa/')}}>BlueGreen Villa</DaySubtitleClickable>
            <DayDesc>
              Cérémonie laïque sous les oliviers dans le jardin de la villa. Cocktail de bienvenue, cérémonie, photos, vin d’honneur, dîner autour de la piscine et fiesta jusqu’au bout de la nuit <span role="img" aria-label="sheep">🎉</span>
            </DayDesc>
          </div>
          <div>
            <DayTitle>17 septembre</DayTitle>
            <DaySubtitle>La fête continue</DaySubtitle>
            <DayDesc>
L’idée est de vous proposer une pool&beach party avec un brunch terre/mer tout au long de cette journée
            </DayDesc>
          </div>
          <div>
            <DayTitle>*15 septembre</DayTitle>
            <DayDesc>
              Nous pouvons faire une sortie tous ensemble la veille du mariage, à voir en fonction du nombre de participants
            </DayDesc>
          </div>
        </Desc>
        <Kids>
            Afin de profiter le plus sereinement possible du mariage, nous vous conseillons de venir sans enfants
          </Kids>
      </TextContainer>
      <BackgroundImage></BackgroundImage>
    </PlanningContainer>
  );
}

const PlanningContainer = styled.div`
  display: flex;

  @media (max-width: 750px) {
    flex-direction: column-reverse;
  }
`;

const BackgroundImage = styled.div`
  background-image: url(${bluegreen});
  width: 62%;
  height: 100vh;
  background-position: 70%;
  background-size: cover;

  @media (max-width: 750px) {
    width: 100%;
    height: 200px;
  }
`;

const TextContainer = styled.div`
  width: 38%;
  background-color: #2d345f;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 750px) {
    width: 100%;
    padding: 20px;
    padding-top: 35px;
  }
`;

const Title = styled.div`
  font-size: 2.5em;
  font-style: italic;
  font-family: basileus;
`;

const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid white;
  width: 40%;
  margin-top: 10px;
  margin-bottom: 35px;
`;

const Desc = styled.div`
  font-weight: 300;
  display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
`;

const DayTitle = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 5px;
`;

const DaySubtitle = styled.div`
  font-size: 1.2em;
  margin-bottom: 5px;
  font-weight: 400;
`;

const DayDesc = styled.div``;

const DaySubtitleClickable = styled(DaySubtitle)`
  cursor: pointer;
`;

const Kids = styled.div`
  padding: 10px;
  border: 2px dashed white;
  position: absolute;
  bottom: 15px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  text-align: center;

  @media (max-width: 750px) {
    position: unset;
    margin-top: 35px;
    width: 100%;
  }
`;

export default Planning;
