import styled from 'styled-components'
import PhotoED from '../assets/PhotoED.jpg';

function PictureSection() {
  return (
    <SectionContainer>
      <BgPicture/>
      <BgShadow/>
      <TextContainer>
        <p>On espère vous compter parmi nous !</p>
        <p>Elyse & Damien</p>
        <p><span role="img" aria-label="sheep">❤️</span></p>
      </TextContainer>
    </SectionContainer>
  );
}

const BgPicture = styled.div`
    background-image: url(${PhotoED});
    width: 100%;
    height: 100vh;
    background-position: 0 -325px;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 750px) {
      background-position: 0 0;
    }
  `;

const TextContainer = styled.div`
    position: absolute;
    bottom: 1%;
    color: white;
    font-style: italic;
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    text-shadow: 0 1px 0 black;

    span {
      font-style: normal;
    }

    p {
      margin: 0.2em 0;
    }
`;

const SectionContainer = styled.div`
  position: relative;
`;

const BgShadow = styled.div`
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6));
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
`;

export default PictureSection