import styled from 'styled-components'
import pegasus from '../assets/pegasus.png'
import chariot from '../assets/chariot.png'
import poseidon from '../assets/poseidon.png'

function GettingThere() {
  return (
    <DetailsContainer id={'getting-there'}>
        <div>
            <SectionTitle>Comment venir ?</SectionTitle>
            <Separator/>
        </div>
        <CardsContainer>
            <DetailsCard>
                <CardTop>
                    <IconContainer>
                        <img src={pegasus} alt={'plane'} />
                    </IconContainer>
                    <CardTitle>Avion</CardTitle>
                </CardTop>
                <Separator />
                <CardBottom>
                    <CardDesc>
                        <div>{'Paris - Mykonos'}</div>
                        <div>{'(#TRANSAVIA // #AIR FRANCE)'}</div>
                        <div>{'3h20 de vol'}</div>
                        <p>Evidemment il y a d’autres moyens, on vous a juste mis les plus simples et ceux qu’on connaît ^^</p>
                    </CardDesc>
                    <CardLink>
                        Voir le vol
                    </CardLink>
                </CardBottom>
            </DetailsCard>
            <DetailsCard>
                <CardTop>
                    <IconContainer>
                        <img src={poseidon} alt={'boat'} />
                    </IconContainer>
                    <CardTitle>Bateau</CardTitle>
                </CardTop>
                <Separator />
                <CardBottom>
                    <CardDesc>
                        <div>{'Ensuite de Mykonos à Paros'}</div>
                        <div>{'Direct : 45 min'}</div>
                        <div>{'Une escale (Naxos) : 1h20'}</div>
                        <p>Pareil plusieurs compagnies de speedboat, on vous a mis celui qu’on prend et qui est rapide <span role="img" aria-label="sheep">🏄‍♂️</span></p>
                    </CardDesc>
                    <CardLink>
                        <a href={'https://www.seajets.com/'} target={'_blank'} rel="noreferrer" >Voir le site</a>
                    </CardLink>
                </CardBottom>
            </DetailsCard>
            <DetailsCard>
                <CardTop>
                    <IconContainer>
                        <img src={chariot} alt={'boat'} />
                    </IconContainer>
                    <CardTitle>Voiture</CardTitle>
                </CardTop>
                <Separator />
                <CardBottom>
                    <CardDesc>
                        <p>C’est un contact directement sur place en face du port de Paros. (Language anglais ou grec uniquement) - env 60€/jours pour une voiture 4 places</p>
                    </CardDesc>
                    <CardLink>
                        <a href={'https://www.google.com/maps/place/Meltemi+Travel+Agency/@37.0854584,25.1497441,17.72z/data=!4m5!3m4!1s0x149871044b29a941:0xcd9aeeb6a0fd16e6!8m2!3d37.0857591!4d25.1504118'} target={'_blank'} rel="noreferrer" >Voir le site</a>
                    </CardLink>
                </CardBottom>
            </DetailsCard>
        </CardsContainer>
    </DetailsContainer>
  );
}

const DetailsContainer = styled.div`
    min-height: calc(100vh - 70px);
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;

    @media (max-width: 750px) {
        padding: unset;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 35px;
    }
`;

const DetailsCard = styled.div`
    width: 25%;

    @media (max-width: 750px) {
        width: 90%;
    }
`;

const CardTop = styled.div``;

const CardBottom = styled.div`
    padding: 35px 25px;
    text-align: center;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 750px) {
        padding: 20px 15px;
    }
`;

const IconContainer = styled.div`
    width: 75px;
    margin: auto;

    img {
        width: 100%;
    }
`;

const CardTitle = styled.div`
    color: #3F4C91;
    font-size: 2em;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 35px;
    font-family: basileus;

    @media (max-width: 750px) {
        margin: 20px 0;
        margin-top: 15px;
    }
`;

const CardDesc = styled.div`
    font-size: 1em;
`;

const CardLink = styled.div`
    border: 2px solid #3F4C91;
    cursor: pointer;
    padding: 8px 20px;
    width: 115px;
    margin: 0 auto;

    a {
        text-decoration: none;
        color: black;
    }
`;

const Separator = styled.div`
    width: 100px;
    heigth: 1px;
    border-bottom: 1px solid #3F4C91;
    margin: auto;
`;

const SectionTitle = styled.div`
    font-size: 2.5em;
    font-style: italic;
    font-family: basileus;
    width: fit-content;
    margin: 15px auto;
    color: #3F4C91;
`;

export default GettingThere