import styled from 'styled-components'
import Logo from './Logo'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Menu() {
  return (
    <MenuContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <LinksContainer>
          <Link href='#details'>Details</Link>
          <Link href='#destination'>Destination</Link>
          <Link href='#getting-there'>Comment venir</Link>
          <Link href='#planning'>Programme</Link>
          <Link href='#accomodation'>Hebergement</Link>
          <Link href='#rsvp'>RSVP</Link>
        </LinksContainer>
      </MenuContainer>
  );
}

const MenuContainer = styled.div`
  padding: 10px;
  background-color: #F5F6F9;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.4);
  position: fixed;
  z-index: 1;
  width: 100%;

  @media (max-width: 750px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  height: 50px;
  width: 50px;
`;

const LinksContainer = styled.div`
  height: fit-content;
  margin: auto 0;
  width: 75%;
  justify-content: space-around;
  display: flex;
`;

const Link = styled(AnchorLink)`
  color: #3F4C91;
  text-decoration: none;
  font-family: basileus;
  font-size: 1.2em;
`;

export default Menu